import React from 'react'
import SEOTools from '../../components/SEOTools'
import { graphql } from 'gatsby'
import { Accordion, Button, Card, Col, Container, Jumbotron, Row } from 'react-bootstrap'
import { GatsbyImage, getImage, getSrc, StaticImage } from 'gatsby-plugin-image'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Layout from '../../components/Layout'
import styled from 'styled-components'
import { faCalendar, faFile } from '@fortawesome/free-regular-svg-icons'

/**
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const NigeriaPage = ({ data }) => {
    const heroBg = getImage(data.heroBg)

    return (
        <Layout>
            <SEOTools
                title='Reach all Nigerian customers'
                description='We offer secure banking and mobile payment services in Nigeria, ensuring reliable solutions for all transactions. Sign up for free.'
                image={getSrc(data.heroBg.childImageSharp)}
            />

            <NigeriaPageStyles>
                <section className='darker-background-overlay position-relative'>
                    <div className='text-white mb-0 d-flex align-items-center full-screen-jumbo'>
                        <Container>
                            <Row>
                                <Col lg={{ span: 6, offset: 1 }} md='8'>
                                    <p className='message'>Collect online payments from all Nigerian customers</p>
                                    <h1 className='name mb-4'>Reach all Nigerian customers</h1>
                                    <p className='message mb-5 pb-3'>
                                        We offer secure banking and mobile payment services in Nigeria, ensuring
                                        reliable solutions for all transactions. Sign up for free.
                                    </p>
                                    <a
                                        className='btn btn-lg px-5 py-3 text-dark bg-white'
                                        href='https://form.jotform.com/241082232255043'
                                        target='_blank'>
                                        Create a free account
                                    </a>
                                </Col>
                            </Row>
                        </Container>
                        <GatsbyImage
                            className='z-index-n1 position-absolute w-100 h-100'
                            alt=''
                            image={heroBg}
                            role='presentation'
                            placeholder='blurred'
                            loading='eager'
                        />
                    </div>
                </section>

                <section id='product-module'>
                    <Jumbotron fluid>
                        <Container>
                            <Row>
                                <Col lg={{ span: 12 }}>
                                    <Row>
                                        <Col md='4' className='py-4'>
                                            <h2>High Conversion</h2>
                                            <p>
                                                Experience the peace of mind that comes with a 98% success rate,
                                                guaranteeing seamless transactions in real time every single time.
                                            </p>
                                        </Col>
                                        <Col md='4' className='py-4'>
                                            <h2>Super Reach</h2>
                                            <p>
                                                Feel the power of seamless connection as you effortlessly reach all
                                                customers through integrated mobile and bank payments. Reach 122 million
                                                mobile phone users and 134 million bank accounts, creating bonds that
                                                span the nation.
                                            </p>
                                        </Col>
                                        <Col md='4' className='py-4'>
                                            <h2>Lowest Prices</h2>
                                            <p>Maximize savings with our competitively priced services.</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </Jumbotron>
                </section>

                <section id='dualcol'>
                    <Jumbotron fluid>
                        <Container>
                            <Row>
                                <Col lg={{ span: 12 }}>
                                    <Row>
                                        <Col md='4' className='py-4'>
                                            <StaticImage
                                                src='../../images/products/nigeria/integration.jpg'
                                                alt='logo'
                                                placeholder='blurred'
                                                className='mb-3 shadow-sm'
                                                height='233'
                                            />
                                            <h2>Integration</h2>
                                            <p>Seamlessly connect to all banks and mobile phone airtime payments.</p>
                                            {/*<Button variant='link' className='px-0 rounded-0'>*/}
                                            {/*    Button >*/}
                                            {/*</Button>*/}
                                        </Col>
                                        <Col md='4' className='py-4'>
                                            <StaticImage
                                                src='../../images/products/nigeria/money2.jpg'
                                                alt='logo'
                                                placeholder='blurred'
                                                className='mb-3 shadow-sm'
                                                height='233'
                                            />
                                            <h2>Settlements</h2>
                                            <p>
                                                Instant real-time payments, including crypto options, are made directly
                                                to your account.
                                            </p>
                                        </Col>
                                        <Col md='4' className='py-4'>
                                            <StaticImage
                                                src='../../images/products/nigeria/voucher.jpg'
                                                alt='logo'
                                                placeholder='blurred'
                                                className='mb-3 shadow-sm'
                                                height='233'
                                            />
                                            <h2>Value-added services</h2>
                                            <p>
                                                Send funds to customers, offer vouchers, or enable bank account
                                                deposits, with various options.
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </Jumbotron>
                </section>

                <section id='dualcol'>
                    <Jumbotron fluid>
                        <Container>
                            <Row>
                                <Col lg={{ span: 12 }}>
                                    <Row>
                                        <Col md='6'>
                                            <CustomAccordion
                                                question='How to start?'
                                                answer='Create a free account to receive the necessary materials and instructions to proceed.
'
                                            />
                                            <CustomAccordion
                                                question='Crypto settlements'
                                                answer='Simply request crypto settlements from us and receive payments in stable currencies of your choice. Settlements can be selected daily, weekly, or monthly.'
                                            />
                                            <CustomAccordion
                                                question='What is included in the product?'
                                                answer='Our product includes bank and mobile airtime payments, automated risk management solutions, and customer support for merchants and end-users.'
                                            />
                                            <CustomAccordion
                                                question='Technical integration'
                                                answer='Our updated API facilitates quick integration, encompassing all payments in one seamless integration. Developers can receive assistance online via chat.'
                                            />
                                        </Col>
                                        <Col md='6'>
                                            <StaticImage
                                                src='../../images/products/nigeria/profit.jpg'
                                                alt='logo'
                                                placeholder='blurred'
                                                className='mb-3 shadow-sm'
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </Jumbotron>
                </section>

                <section id='dualcol' className='bg-light'>
                    <Jumbotron fluid className='mb-0'>
                        <Container>
                            <Row className='justify-content-center'>
                                <Col lg={{ span: 10 }}>
                                    <h2>Get started</h2>
                                    <Row>
                                        <Col md='6' className='py-4'>
                                            <Card body className='border-0 h-100'>
                                                <div className='py-3'>
                                                    <FontAwesomeIcon icon={faCalendar} size='lg' />
                                                </div>
                                                <h3>Talk to us</h3>
                                                <p className='mb-4'>
                                                    Our teams are on hand to advise and answer your questions.
                                                </p>
                                                <a
                                                    href='https://calendly.com/ilkka-seppanen/meeting-with-ilkka-seppanen?month=2024-04'
                                                    target='_blank'
                                                    className='btn btn-success px-4'>
                                                    Book a meeting
                                                </a>
                                            </Card>
                                        </Col>
                                        <Col md='6' className='py-4'>
                                            <Card body className='border-0 h-100'>
                                                <div className='py-3'>
                                                    <FontAwesomeIcon icon={faFile} size='lg' />
                                                </div>
                                                <h3>Start building</h3>
                                                <p className='mb-4'>
                                                    Learn more about the product pricing included and explore our APIs
                                                </p>
                                                <a
                                                    href='https://form.jotform.com/241082232255043'
                                                    target='_blank'
                                                    className='btn btn-success px-4'>
                                                    Download
                                                </a>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </Jumbotron>
                </section>
            </NigeriaPageStyles>
        </Layout>
    )
}

export const query = graphql`
    query {
        heroBg: file(relativePath: { eq: "products/nigeria/black_couple_phone.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 3840, placeholder: BLURRED, quality: 80, transformOptions: { cropFocus: CENTER })
            }
        }
        checkOutBg: file(relativePath: { eq: "products/sirusms/sirumobile_product_sms.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
    }
`

export default NigeriaPage

const CustomAccordion = ({ question, answer, slug = undefined }) => (
    <StyledAccordion className='mb-4'>
        <div className='pb-0' id={slug}>
            <Accordion.Toggle
                as={Button}
                variant='link'
                className='px-0 mt-0 text-left accordion-opener w-100 pb-3'
                eventKey='0'>
                <Row>
                    <Col xs='10' className='h4 py-0 my-0 bold'>
                        {question}
                    </Col>
                    <Col xs='2' className='d-flex'>
                        <div className='text-right d-flex align-items-center justify-content-end'>
                            <FontAwesomeIcon icon={faChevronDown} />
                        </div>
                    </Col>
                </Row>
            </Accordion.Toggle>
        </div>
        <Accordion.Collapse eventKey='0'>
            <div className='py-3' itemProp='text'>
                {answer}
            </div>
        </Accordion.Collapse>
    </StyledAccordion>
)

const StyledAccordion = styled(Accordion)`
    &:not(:last-child) {
        border-bottom: 1px solid;
    }

    .card:hover {
        border-color: var(--green) !important;
    }

    .btn-link {
        color: var(--gray);
        font-size: 1.1rem;
    }
    .btn-link:focus {
        box-shadow: none;
        text-decoration: none;
    }
    .btn-link:hover {
        text-decoration: none;
        color: var(--green);
    }
`

const NigeriaPageStyles = styled.div`
    .name {
        font-size: 3.5rem;
        font-weight: 600;
        text-shadow: var(--black) 0 0 3em;
    }
    .message {
        font-size: 1.4rem;
        text-shadow: var(--black) 0 0 3em;
    }

    //    @TODO: for smaller displays, make font size smaller for these previous classess
    @media (max-width: 508px) {
        .name {
            font-size: 3rem;
        }
        .message {
            font-size: 1.2rem;
        }
    }
`
